.tbcds-input {
    display: block !important;
    font-family: "TBC Sailec", sans-serif !important;
    font-weight: 600 !important;
    margin-bottom: 1rem;

    &.hint-input {
        margin-bottom: 20px;
    }

    input, label {
        font-size: 14px;
    }

    mat-error {
        font-size: 10px;
        font-weight: normal !important;
        margin-left: 8px;
        margin-top: 4px;
    }

    mat-hint {
        font-size: 10px;
        font-weight: normal !important;
        margin-top: 4px;
    }

    input, label, mat-hint, mat-error {
        line-height: 16px;
    }

    input {
        color: #222222;
        background-color: #F3F3F3;
        padding: 31px 16px 9px;
        margin: 0;
        box-sizing: border-box;
        border-radius: 4px;

        &:hover {
            background-color: #ececec;
        }

        &:focus {
            box-shadow: inset 0 0 0 1px #00aeef;
        }
    }

    &.with-link {
        input {
            padding-right: 104px;
        }
    }

    &.mat-form-field-invalid, &.input-error {
        input {
            box-shadow: inset 0 0 0 1px #eb4949;
        }

        .mat-input-element {
            caret-color: unset;
        }
    }

    &.mat-focused .mat-form-field-label, label, mat-hint {
        color: #737373 !important;
    }

    mat-error {
        color: #eb4949;
    }

    label {
        top: 2.7em !important;
        left: 16px;
    }

    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    &.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
        transform: translateY(-12px) scale(0.9) perspective(100px) translateZ(0.001px) !important;
        width: 100% !important;
    }

    .mat-form-field-underline {
        display: none;
    }

    .mat-form-field-infix {
        width: auto;
        border-top: none;
    }

    .mat-form-field-wrapper {
        padding-bottom: 4px !important;
    }

    .mat-form-field-label {
        color: #505058;
    }
}

.field-link {
    position: absolute;
    right: 1rem;
    top: 26px;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    color: #00AEEF !important;
    text-decoration: none !important;
}