@mixin transition($value) {
    -webkit-transition: $value;
    -moz-transition: $value;
    -ms-transition: $value;
    -o-transition: $value;
    transition: $value;
}

@mixin transition2($value1, $value2) {
    -webkit-transition: $value1, $value2;
    -moz-transition: $value1, $value2;
    -ms-transition: $value1, $value2;
    -o-transition: $value1, $value2;
    transition: $value1, $value2;
}

@mixin boxShadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    -ms-box-shadow: $value;
    -o-box-shadow: $value;
    box-shadow: $value;
}
