h3,
.h3 {
	font-size: 1rem;
}

h2,
.h2 {
	font-size: 1.5rem;
}

h1,
.h1 {
	font-size: 2rem;
}

.fs-18 {
	font-size: 18px;
}

.fs-16 {
	font-size: 16px;
}

.fs-14 {
	font-size: 14px;
}

// __<<ngThemingMigrationEscapedComment0>>__
// @media (min-width: 768px) {
// }

// __<<ngThemingMigrationEscapedComment1>>__
// @media (min-width: 992px) {
// }

// __<<ngThemingMigrationEscapedComment2>>__
// @media (min-width: 1200px) {
// }
