@import "../mixins.scss";

.custom-button-new {
	text-decoration: none !important;
	display: inline-block;
	border-radius: 4px;
	border: none;
	background-color: #00AEEF;
	color: #ffffff !important;
	cursor: pointer;
	padding: 22px 50px 18px;
	line-height: 13px;
	font-size: 14px;
	text-align: center;
	font-weight: 800;
	transition-property: background-color, color;
	@include transition(background-color 150ms linear);
	user-select: none;
    outline: none !important;
    -webkit-appearance: none;
	-webkit-font-feature-settings: "case";
	-moz-font-feature-settings: "case";
	font-feature-settings: "case" on;

	&.max-w {
		@media (max-width: 550px) {
			padding-left: 8px;
			padding-right: 8px;
			margin: auto;
		}
	}

	&.outline {
		background-color: white;
		border: 1px solid #ACACAC;
		color: #737373 !important;

		&:hover {
			background-color: white !important;
		}
	}

	&.with-arrow {
		.arrow {
			opacity: 0;
			transition: opacity 175ms ease-in-out;
		}

		.text {
			transition: transform 250ms ease-in-out;
		}

		&:hover {
			.text {
				transform: translateX(12px);
				transition: transform 175ms ease-in-out;
			}

			.arrow {
				opacity: 1;
				transition: opacity 250ms ease-in-out;
			}
		}
	}

	&:hover {
		will-change: background-color;
		background-color: #1cbcff !important;
	}

	&:not(.displayLoader):disabled {
		cursor: default;
		background-color: #00AEE2 !important;
		opacity: 0.6;

		.text {
			opacity: 0.8;
		}

		&:hover {
			.arrow {
				opacity: 0;
			}
			.text {
				transform: none;
			}
		}
	}
}

$arrow-size: 10px;
$arrow-border: 0.2em;

.arrow {
	width: fit-content;
	margin: auto;
	position: relative;
	font-size: 12px;
	top: 5px;
	left: -38px;

	span {
		font-size: 16px;
	}
	
	&:after, &:before {
		content: "";
		display: block;
		position: absolute;
	}
	&:before {
		top: 50%;
		left: -20px;
		width: $arrow-size;
		height: $arrow-size;
		transform-origin: 50% 50%;
		transform:  translateY(-50%) rotate(45deg);
		box-shadow: inset -#{$arrow-border} $arrow-border 0 0 white;
		border-radius: 2px 2px 2px 0px;
	}
	
	&:after {
		top: 50%;
		left: -22px;
		width: 12px;
		height: 2px;
		border-radius: 2px;
		transform: translateY(-50%);
		background-color: white;
	}
}

.displayLoader {
	position: relative;

	.button-content-wrap {
		opacity: 0;
	}

	.loader {
		display: block;
		position: absolute;
		left: calc(50% - 13px);
		top: 12px;

		.sk-chase {
			width: 26px;
			height: 26px;
			position: relative;
			animation: sk-chase 2.5s infinite linear both;

			.sk-chase-dot {
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				animation: sk-chase-dot 2s infinite ease-in-out both;

				&:before {
					content: "";
					display: block;
					width: 25%;
					height: 25%;
					background-color: #fff;
					border-radius: 100%;
					animation: sk-chase-dot-before 2s infinite ease-in-out both;
				}

				&:nth-child(1) {
					animation-delay: -1.1s;
				}
				&:nth-child(2) {
					animation-delay: -1s;
				}
				&:nth-child(3) {
					animation-delay: -0.9s;
				}
				&:nth-child(4) {
					animation-delay: -0.8s;
				}
				&:nth-child(5) {
					animation-delay: -0.7s;
				}
				&:nth-child(6) {
					animation-delay: -0.6s;
				}
				&:nth-child(1):before {
					animation-delay: -1.1s;
				}
				&:nth-child(2):before {
					animation-delay: -1s;
				}
				&:nth-child(3):before {
					animation-delay: -0.9s;
				}
				&:nth-child(4):before {
					animation-delay: -0.8s;
				}
				&:nth-child(5):before {
					animation-delay: -0.7s;
				}
				&:nth-child(6):before {
					animation-delay: -0.6s;
				}
			}
		}

		@keyframes sk-chase {
			100% {
				transform: rotate(360deg);
			}
		}

		@keyframes sk-chase-dot {
			80%,
			100% {
				transform: rotate(360deg);
			}
		}

		@keyframes sk-chase-dot-before {
			50% {
				transform: scale(0.4);
			}
			100%,
			0% {
				transform: scale(1);
			}
		}

		@-webkit-keyframes load3 {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		@keyframes load3 {
			0% {
				-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
	}
}