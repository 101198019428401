.tbcds-file {
    display: block !important;
    font-family: "TBC Sailec", sans-serif !important;
    font-weight: 600 !important;
    margin-bottom: 25.25px;
    font-size: 14px;
    border: 1px dashed #7070707c;
    border-radius: 8px;
    padding: 28.5px 1rem;
    width: 100%;
    cursor: pointer;
    outline: none;
    position: relative;

    // color: #eb4949;
    // color: #00aeef;

    // mat-hint, mat-error {
    //     font-size: 10px;
    //     font-weight: normal !important;
    //     margin-left: 8px;
    //     margin-top: 4px;
    //     line-height: 16px;
    // }

    img {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
    }
}