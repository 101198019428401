.generic-spacer {
	padding: 4rem 0;

	@media (max-width: 768px) {
		padding: 3rem 0;
	}
	@media (max-width: 430px) {
		padding: 2rem 0;
	}
}

.generic-spacer-bottom {
	padding-bottom: 56px;

	@media (max-width: 768px) {
		padding-bottom: 3rem;
	}
	@media (max-width: 430px) {
		padding-bottom: 2rem;
	}
}

.generic-spacer-top {
	padding-top: 4rem;

	@media (max-width: 768px) {
		padding-top: 3rem;
	}
	@media (max-width: 430px) {
		padding-top: 2rem;
	}
}

.generic-margin-bottom {
	margin-bottom: 4rem;

	@media (max-width: 768px) {
		margin-bottom: 3rem;
	}
	@media (max-width: 430px) {
		margin-bottom: 2rem;
	}
}

.generic-navbar-spacer {
	padding-top: 86px;
}
