.tbcds-radio {
    margin-bottom: 25.25px;
    display: flex !important;

    mat-radio-button {
        background: #F3F3F3 0% 0% no-repeat padding-box;
        border-radius: 4px;
        width: 100%;

        .mat-ripple-element{
            display: none !important;
        }

        label {
            margin: 0;
            padding: 17.5px 1rem;
            color: #737373 !important;
            font-family: "TBC Sailec", sans-serif !important;
            font-weight: 600 !important;
        }

        &.mat-radio-button.mat-accent {
            &.mat-radio-checked .mat-radio-outer-circle {
                border-color: #00aeef;
                background-color: #F3F3F3;
            }

            .mat-radio-inner-circle {
                background-color: #00aeef;
            }
        }
        
        &.mat-radio-checked .mat-radio-inner-circle {
            transform: scale(0.67);
        }
        
        .mat-radio-outer-circle {
            border-width: 1px;
            border-color: #DADADA;
            background-color: #DADADA;
        }

        .mat-radio-container,
        .mat-radio-inner-circle,
        .mat-radio-outer-circle {
            width: 18px;
            height: 18px;
        }
    }
}

.tbcds-title {
    font-weight: 600;
    color: #4B4B4B;
    font-size: 14px;
}