$alice-blue: #f9fbff;
$blue: #00aeef;
$light-blue: #1cbcff;
$gray: #5b656e;
$lightgray: #b9bfc6;
$darkgray: #969CA2;
$blue-link: #26abff;
$light-blue-transparent: #DEF2FF;
$border-blue: #e2ecf7;
$pale-blue: #c7e7ff;
$light-gray-border: #cddae8;
$description-color: #485360;
$link-water: #DFEBF7;

$light-green1: #32BB80;
$red: #E02F0F;
$error-red: #EB4A49;

$title-color: #222;
$body-text-default-color: #454545;