.tbcds-dropdown,
mat-option {
    font-family: "TBC Sailec", sans-serif !important;
    font-weight: 600 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-option-text {
    color: #222222 !important;
}

.tbcds-dropdown-panel {
    margin-top: 25px;

    &.multiple {
        min-width: calc(100% + 32px) !important;
        transform: scaleY(1) translateX(6px) !important;

        @media (min-width: 768px) {
            transform: scaleY(1) translateX(24px) !important;
        }

        .mat-pseudo-checkbox {
            background-color: #ececec !important;
            border: 1px solid #dadada;

            &.mat-pseudo-checkbox-checked,
            &.mat-pseudo-checkbox-indeterminate {
                background-color: #00aeef !important;
                border-color: #00aeef !important;

                &:after {
                    left: 2px;
                }
            }
        }
    }

    .mat-option-disabled {
        .mat-option-text {
            color: #5b656e !important;
        }
    }
}

.tbcds-dropdown {
    display: block !important;
    margin-bottom: 4px;
    padding-top: 4px;
    color: #737373 !important;

    &.hint-dropdown {
        margin-bottom: 20px;
    }

    .mat-form-field-underline {
        display: none;
    }

    .mat-select-value-text {
        font-family: "TBC Sailec", sans-serif !important;
    }

    .mat-select-trigger,
    label {
        height: 16px;
    }

    .mat-select-value {
        color: #222222;
    }

    .mat-form-field-flex {
        padding-top: 0.367em;
    }

    &.mat-focused .mat-form-field-infix {
        box-shadow: inset 0 0 0 1px #00aeef;
    }

    .mat-form-field-infix {
        position: relative;
        background-color: #f3f3f3;
        border-radius: 4px;
        border-top: 0;
        padding: 31px 16px 9px !important;

        &:before {
            content: url(/assets/images/dropdown-icon.svg);
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
        }

        &:hover {
            background-color: #ececec;
        }
    }

    &.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
        .mat-form-field-label-wrapper
        .mat-form-field-label {
        transform: translateY(-12px) scale(0.9) perspective(100px) translateZ(0.001px) !important;
        width: 100% !important;
    }

    .mat-form-field-subscript-wrapper {
        margin-top: 0 !important;
    }

    mat-error {
        color: #eb4949;
    }

    mat-error {
        font-size: 10px;
        font-weight: normal !important;
        margin-left: 8px;
        margin-top: 4px;
    }

    mat-hint {
        font-size: 10px;
        font-weight: normal !important;
        margin-top: 4px;
    }

    .mat-form-field-label {
        color: #737373 !important;
    }

    &.mat-form-field-invalid .mat-form-field-infix {
        box-shadow: inset 0 0 0 1px #eb4949;
    }

    .mat-form-field-label-wrapper {
        top: 3px;
        left: 1rem;
        width: calc(100% - 2rem) !important;
    }

    .mat-select-arrow {
        visibility: hidden;
    }
}
