@font-face {
  font-family: "TBC Sailec";
  src: url(/assets/fonts/TBCSailec-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "TBC Sailec";
  src: url(/assets/fonts/TBCSailec-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "TBC Sailec";
  src: url(/assets/fonts/TBCSailec-Medium.ttf) format("truetype");
  font-weight: 600;
}
