.tbcds-textarea {
    display: block !important;
    font-family: "TBC Sailec", sans-serif !important;
    font-weight: 600 !important;
    margin-bottom: 1rem;
    padding-top: 4px;

    &.hint-input {
        margin-bottom: 20px;
    }

    textarea,
    label {
        font-size: 14px;
    }

    mat-error {
        font-size: 10px;
        font-weight: normal !important;
        margin-left: 8px;
        margin-top: 4px;
    }

    mat-hint {
        font-size: 10px;
        font-weight: normal !important;
        margin-top: 4px;
    }

    textarea,
    label,
    mat-hint,
    mat-error {
        line-height: 16px;
    }

    .mat-form-field-infix {
        background-color: #f3f3f3;
        border-radius: 4px;

        &:hover {
            background-color: #ececec;
        }

        padding: 37px 0 0 !important;
    }

    textarea {
        color: #222222;
        padding: 0 16px 9px !important;
        margin-bottom: 0 !important;
        box-sizing: border-box;
        border-radius: 4px;

        @media (min-width: 768px) {
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
                background-color: #ececec;
            }

            &::-webkit-scrollbar {
                width: 8px;
                background-color: #ececec;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
    }

    &.mat-focused {
        .mat-form-field-infix {
            box-shadow: inset 0 0 0 1px #00aeef;
        }
    }

    &.mat-form-field-invalid {
        .mat-form-field-infix {
            box-shadow: inset 0 0 0 1px #eb4949;
        }

        textarea {
            caret-color: unset;
        }
    }

    &.mat-focused .mat-form-field-label,
    label,
    mat-hint {
        color: #737373 !important;
    }

    mat-error {
        color: #eb4949;
    }

    label {
        top: 2.7em !important;
        left: 16px;
    }

    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    &.mat-form-field-can-float
        .mat-form-field-autofill-control:-webkit-autofill
        + .mat-form-field-label-wrapper
        .mat-form-field-label {
        transform: translateY(-12px) scale(0.9) perspective(100px) translateZ(0.001px) !important;
        width: 100% !important;
    }

    .mat-form-field-underline {
        display: none;
    }

    .mat-form-field-infix {
        width: auto;
        border-top: none;
    }

    .mat-form-field-wrapper {
        padding-bottom: 4px !important;
    }

    .mat-form-field-label {
        color: #505058;
    }

    .mat-form-field-subscript-wrapper {
        top: calc(100% - 1.6666666667em + 0.4375em) !important;
    }
}
