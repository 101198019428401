@import "./mixins.scss";
@import "./palette.scss";

.plain-button {
	color: #00aeef;
	font-size: 14px;
	font-weight: bold;
	padding: 19px 1rem 16px;
	cursor: pointer;
	user-select: none;

	transition: color ease-in-out 175ms;

	&:hover {
		color: #1cbcff;
		transition: color ease-in-out 175ms;
	}
}

.custom-button {
	text-decoration: none !important;
	display: inline-block;
	border-radius: 4px;
	border: none;
	background-color: $blue;
	color: #ffffff !important;
	cursor: pointer;
	padding: 19px 3rem 16px 3rem;
	line-height: 13px;
	font-size: 14px;
	text-align: center;
	font-weight: 800;
	-webkit-font-feature-settings: "case";
	-moz-font-feature-settings: "case";
	font-feature-settings: "case" on;
	transition-property: background-color, color;
	@include transition(background-color 200ms linear);
	user-select: none;
	-webkit-appearance: none;

	&:hover {
		will-change: background-color;
		background-color: #1cbcff !important;
	}

	&.disabled {
		cursor: default;
		background-color: #dcdcdc !important;
	}

	&.white {
		background-color: transparent;
		color: $blue !important;

		&:hover {
			background-color: transparent !important;
			@include boxShadow(inset 0px 0px 0px 1px $blue);
			@include transition(all 200ms linear);
		}
	}

	&.button-on-hover {
		background-color: transparent;
		color: $blue-link !important;

		&:hover {
			background-color: $blue-link;
			color: white !important;
		}

		&.disabled {
			background-color: transparent !important;
			color: #888c90 !important;

			&:hover {
				color: #888c90 !important;
			}
		}
	}

	&.displayLoader {
		.loader {
			.sk-chase {
				width: 26px;
				height: 26px;
				position: relative;
				animation: sk-chase 2.5s infinite linear both;

				.sk-chase-dot {
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					animation: sk-chase-dot 2s infinite ease-in-out both;

					&:before {
						content: "";
						display: block;
						width: 25%;
						height: 25%;
						background-color: #fff;
						border-radius: 100%;
						animation: sk-chase-dot-before 2s infinite ease-in-out both;
					}

					&:nth-child(1) {
						animation-delay: -1.1s;
					}
					&:nth-child(2) {
						animation-delay: -1s;
					}
					&:nth-child(3) {
						animation-delay: -0.9s;
					}
					&:nth-child(4) {
						animation-delay: -0.8s;
					}
					&:nth-child(5) {
						animation-delay: -0.7s;
					}
					&:nth-child(6) {
						animation-delay: -0.6s;
					}
					&:nth-child(1):before {
						animation-delay: -1.1s;
					}
					&:nth-child(2):before {
						animation-delay: -1s;
					}
					&:nth-child(3):before {
						animation-delay: -0.9s;
					}
					&:nth-child(4):before {
						animation-delay: -0.8s;
					}
					&:nth-child(5):before {
						animation-delay: -0.7s;
					}
					&:nth-child(6):before {
						animation-delay: -0.6s;
					}
				}
			}

			@keyframes sk-chase {
				100% {
					transform: rotate(360deg);
				}
			}

			@keyframes sk-chase-dot {
				80%,
				100% {
					transform: rotate(360deg);
				}
			}

			@keyframes sk-chase-dot-before {
				50% {
					transform: scale(0.4);
				}
				100%,
				0% {
					transform: scale(1);
				}
			}

			@-webkit-keyframes load3 {
				0% {
					-webkit-transform: rotate(0deg);
					transform: rotate(0deg);
				}
				100% {
					-webkit-transform: rotate(360deg);
					transform: rotate(360deg);
				}
			}
			@keyframes load3 {
				0% {
					-webkit-transform: rotate(0deg);
					transform: rotate(0deg);
				}
				100% {
					-webkit-transform: rotate(360deg);
					transform: rotate(360deg);
				}
			}
		}
	}
}
