.tbcds-radio-plain {
    mat-radio-button {
        width: 100%;

        .mat-ripple-element{
            display: none !important;
        }

        label {
            margin: 0;
            color: #737373 !important;
            font-family: "TBC Sailec", sans-serif !important;
            font-weight: 600 !important;
        }

        .mat-radio-label-content {
            padding-left: 16px;
        }

        &.mat-radio-button.mat-accent {
            &.mat-radio-checked .mat-radio-outer-circle {
                border-color: #00aeef;
                background-color: #F3F3F3;
            }

            .mat-radio-inner-circle {
                background-color: #00aeef;
            }
        }
        
        &.mat-radio-checked .mat-radio-inner-circle {
            transform: scale(0.67);
        }
        
        .mat-radio-outer-circle {
            border-width: 1px;
            border-color: #DADADA;
            background-color: #DADADA;
        }

        .mat-radio-container,
        .mat-radio-inner-circle,
        .mat-radio-outer-circle {
            width: 18px;
            height: 18px;
        }
    }
}