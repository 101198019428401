@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "./size/font.scss";
@import "./size/space.scss";
@import "./button.scss";
@import "./fonts.scss";
@import "./palette.scss";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "../../../node_modules/swiper/swiper-bundle.min.css";

@import "./tbcds/file.scss";
@import "./tbcds/input.scss";
@import "./tbcds/tooltip.scss";
@import "./tbcds/dropdown.scss";
@import "./tbcds/textarea.scss";
@import "./tbcds/button-new.scss";
@import "./tbcds/radio-button.scss";
@import "./tbcds/radio-button-plain.scss";
@import "../ckeditor/styles/ckeditor.scss";


body {
	background-color: white;
	font-family: "TBC Sailec", sans-serif;
	color: $body-text-default-color;
	font-size: 14px;
}

input, textarea, select, button {
	max-height: 100%;
	-webkit-text-size-adjust: none;
  }

.font-weight-medium {
	font-weight: 600;
}

.support-component-header {
	margin-bottom: 4rem;
}

.py-2rem {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.pt-2rem {
	padding-top: 2rem;
}

.pb-2rem {
	padding-bottom: 2rem;
}

.my-2rem {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.mt-2rem {
	margin-top: 2rem;
}

.mb-2rem {
	margin-bottom: 2rem;
}

.mt-6px {
	margin-top: 6px;
}

a {
	padding: 0;
	text-decoration: none;
	color: inherit;

	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

ul {
	align-items: center;
	list-style-type: none;
	padding-inline-start: 0;
	margin-block-start: 0;
	margin-block-end: 0;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	padding: 0;
}

h1 {
	color: $title-color;
}

.component-header {
	font-size: 32px;
	text-align: center;
}

.form-button-width {
	max-width: 200px;
}

.custom-form-check {
	.mat-checkbox-layout {
		background-color: #f3f3f3;
		width: 100%;
		display: inline-block;
		margin-bottom: 0;
		padding: 16px;
		padding-right: 36px;
		border-radius: 4px;

		.mat-ripple-element {
			display: none !important;
		}
		.mat-checkbox-frame {
			border: 1px solid #adadad !important;
			background-color: #dadada;
		}
		.mat-checkbox-label {
			font-weight: 600;
			color: #4b4b4b;
			font-family: "TBC Sailec", sans-serif !important;
			cursor: default;
		}
	}
	&.mat-checkbox-checked .mat-checkbox-background {
		background-color: #00aeef !important;
	}
}

mat-checkbox {
	tbcds-tooltip {
		div {
			width: 56px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}
}

.wrap {
	max-width: 1200px;
	margin: 0 auto;
}

.background-wrapper-white {
	background-color: white;
}

.background-color-alice-blue {
	background-color: $alice-blue;
}

.vertical-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.white {
	color: #ffffff;
}

.caps {
	-webkit-font-feature-settings: "case";
	-moz-font-feature-settings: "case";
	font-feature-settings: "case" on;
}

.over-footer {
	position: relative;
	z-index: 2;
}

h1,
.h1,
h2,
.h2 {
	letter-spacing: -0.5px;
}

.link {
	color: $blue;
	cursor: pointer;

	&:hover {
		color: #1cbbffdc;
	}
}

mat-form-field.full-border {
	width: 100%;

	&.mat-focused {
		input {
			border-color: $blue-link;
		}

		.mat-form-field-label {
			color: $blue-link;
		}
	}

	&.mat-form-field-invalid {
		input {
			border-color: #f44336;
		}

		.mat-form-field-label {
			color: #f44336;
		}

		&.mat-select {
			.mat-form-field-infix {
				border-color: #f44336;
			}
		}
	}

	input {
		border: 1px solid #cddae8;
		border-radius: 8px;
		padding: 15px 16px;
		@include transition(border-color 0.2s ease-in-out);
	}

	.mat-form-field-label-wrapper {
		top: 0;
		left: 16px;

		.mat-form-field-label {
			background-color: white;
			display: inline-block;
			width: auto !important;
			padding: 0 4px;
		}
	}

	.mat-form-field-subscript-wrapper {
		margin-top: 0;
		text-indent: 20px;
	}

	.mat-form-field-underline {
		display: none;
	}

	.mat-form-field-infix {
		border-top: 0;
	}

	&.mat-select {
		.mat-form-field-infix {
			border: 1px solid #cddae8;
			border-radius: 8px;
			padding: 15px 16px;
		}

		.mat-form-field-label-wrapper {
			overflow: visible;

			.mat-form-field-label {
				top: 0.9em;
			}
		}
	}
}

.swiper {
	//width: 100vw;
	padding-bottom: 30px;
}

.swiper-slide {
	display: flex;
	justify-content: center;
}

.swiper-pagination.swiper-pagination-progressbar.swiper-pagination-horizontal{
	top: unset;
	//right: 120px;
	bottom: 0px;
	left: 0;
	width: 90%;
	height: 2px;
	background-color: #EEF1F1;
	
	.swiper-pagination-progressbar-fill {
		background-color: #00ADEE;
	}
}


.slick-buttons {
	display: flex;

	.slide-left {
		button {
			img {
				transform: rotate(180deg);
			}
		}
	}
}

.arrow-btn {
	border: none;
	background-color: transparent;
	user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	&:active {
		outline: none;
	}

	&:disabled {
		img {
			content: url("/assets/images/paginator-arrow.svg");
		}
	}

	.arrow-img {
		width: 21px;
		height: 14px;
	}
}

@media screen and (max-width: 600px) {
	.swiper-pagination.swiper-pagination-progressbar.swiper-pagination-horizontal{
		width: 75%;
	}
}


@media screen and (min-width: 600px) and (max-width: 1000px) {
	.swiper-pagination.swiper-pagination-progressbar.swiper-pagination-horizontal{
		width: 85%;
	}
}
